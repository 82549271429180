import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import client from "../client";
import handleApiError from "../../hooks/handleApiError";

export default function useFetchClassReportCard(session, term) {
  const { classId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const request = useCallback(
    async (_session, _term) => {
      setData([]);
      setLoading(true);

      try {
        const res = await client.get(`/result/${classId}/reportcard`, {
          params: {
            session: _session,
            term: _term,
          },
        });

        if (res.data?.success) {
          // setData(res.data?.data);
          setData(
            res.data?.data?.sort((a, b) =>
              a?.lastName?.toLowerCase() > b?.lastName?.toLowerCase() ? 1 : -1
            )
          );
        }

        setLoading(false);
        return true;
      } catch (error) {
        setLoading(false);
        handleApiError(error);
        return false;
      }
    },
    [classId]
  );

  useEffect(() => {
    if (session && term) request(session, term);
  }, [request, session, term]);

  return { data, loading, request };
}
