import { useState } from "react";
import { MdFilterList, MdFilterListOff } from "react-icons/md";

import Loader from "../../../../shared/components/Loader";
import FormButton from "../../../../shared/components/form/FormButton";

const ChooseSubjects = ({
  title,
  filter,
  loading,
  subjects,
  onFilter,
  subjectLoading,
  createReportCard,
  selectedSubjects,
  handleSelectSubject,
  category,
}) => {
  const [showFilter, setShowFilter] = useState(false);

  return (
    <div className="bg-white pb-4 rounded-md">
      <div className="mb-5 flex items-center justify-between gap-4">
        <h5 className="text-independence text-base">
          {title || "Class Subjects"}
        </h5>

        {category?.startsWith("SSS") ? (
          <div
            className="pl-3 cursor-pointer"
            onClick={() => setShowFilter(!showFilter)}
          >
            {showFilter ? (
              <MdFilterListOff size={25} />
            ) : (
              <MdFilterList size={25} />
            )}
          </div>
        ) : null}
      </div>

      {showFilter ? (
        <div className="flex items-center justify-between gap-1 mb-5">
          {filters?.map((item, i) => (
            <button
              key={i}
              className={`flex-1 text-sm py-1 px-2 ${
                filter === item.value ? "border border-primary rounded-sm" : ""
              }`}
              onClick={() => onFilter(item.value)}
            >
              {item.label}
            </button>
          ))}
        </div>
      ) : null}

      {subjectLoading ? <Loader /> : null}

      <div>
        {!subjectLoading &&
          subjects?.map((subject, i) => {
            const index = selectedSubjects?.findIndex(
              (sub) => sub?.classSubjectId === subject?.classSubjectId
            );

            return (
              <div
                key={i}
                className={`flex items-center px-3 mb-2 h-11 rounded-sm cursor-pointer ${
                  index >= 0 ? "bg-white border border-primary" : "bg-cultured "
                }`}
                onClick={() => handleSelectSubject(subject)}
              >
                <p
                  className={`text-sm font-semibold capitalize ${
                    index >= 0 ? "text-primary" : "text-yankee_blue"
                  }`}
                >
                  {subject?.name}
                </p>
              </div>
            );
          })}
      </div>

      <FormButton
        title={"Create Report card"}
        loading={loading}
        onClick={createReportCard}
        className={"mt-10"}
      />
    </div>
  );
};

const filters = [
  { label: "All", value: "all" },
  { label: "Art", value: "art" },
  { label: "Science", value: "science" },
  { label: "Commercial", value: "commercial" },
];
export default ChooseSubjects;
