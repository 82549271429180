import React from "react";
import EmptyTable from "../../EmptyTable";
import Loader from "../../Loader";

export default function ArchivedStudentTable({
  data,
  loading,
  showActionColumn,
  handlePromoteToSeniorClass,
}) {
  console.log(data, "data");
  return (
    <div className="overflow-auto max-w-[90vw] min-h-[500px]">
      <div className="min-w-[700px]">
        {/* table header */}
        <div
          className={`grid border-b ${
            showActionColumn ? "grid-cols-7" : "grid-cols-6"
          }`}
        >
          <div className="col-span-2 border-r p-3">
            <h5 className="font-semibold text-sm">Student Name</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Class</h5>
          </div>

          <div className="border-r p-3">
            <h5 className="font-semibold text-sm">Department</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Entrance Year</h5>
          </div>

          <div className="p-3 border-r">
            <h5 className="font-semibold text-sm">Graduation Year</h5>
          </div>

          {showActionColumn ? (
            <div className="p-3">
              <h5 className="font-semibold text-sm">Action</h5>
            </div>
          ) : null}
        </div>

        {loading ? <Loader loading={loading} /> : null}

        {!loading && !data?.length ? (
          <EmptyTable
            // image={subjectBookImage}
            message={"No record found"}
          />
        ) : null}

        {/* Table body */}
        {data?.map((item, i) => (
          <div
            className={`grid border-b cursor-pointer hover:bg-primary_light_20 ${
              showActionColumn ? "grid-cols-7" : "grid-cols-6"
            }`}
            key={i}
          >
            <div className="col-span-2 border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.student?.lastName} {item?.student?.firstName}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.category || "N/A"}
              </p>
            </div>

            <div className="border-r p-3">
              <p className="text-independence text-sm capitalize">
                {item?.student?.department || "N/A"}
              </p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm">{item?.entrySession}</p>
            </div>

            <div className="p-3 border-r">
              <p className="text-independence text-sm capitalize">
                {item?.session || "N/A"}
              </p>
            </div>

            {showActionColumn && !item?.student?.category ? (
              <div
                className=""
                onClick={() => handlePromoteToSeniorClass(item)}
              >
                <p className="text-independence text-sm m-2 px-3 w-fit py-1 bg-white shadow hover:bg-primary hover:text-white">
                  Add to SS1
                </p>
              </div>
            ) : null}

            {showActionColumn && item?.student?.category ? (
              <div className="p-3">
                <p className="text-primary text-sm">Promoted</p>
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}
