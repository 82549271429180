import React, { useState } from "react";

export default function useGetReportCardActions(handleMark) {
  const [index, setIndex] = useState(null);
  const [showButton, setShowButton] = useState(false);

  function getReportCardActions(card, i) {
    if (card?.studentClassHistory?.endedSessionAs) {
      return (
        <p className="text-independence text-sm capitalize">
          {card?.studentClassHistory?.endedSessionAs}
        </p>
      );
    }

    if (!card?.studentClassHistory?.endedSessionAs) {
      return (
        <div
          className="relative w-full"
          onClick={() => {
            setShowButton(!showButton);
            setIndex(i);
          }}
        >
          <small className="text-yankee_blue">N/A</small>

          {showButton && index === i ? (
            <div className="absolute top-9 -right-5 -left-5 flex flex-col bg-white mx-1 z-20 shadow-lg rounded-lg border">
              {card?.category === "JSS3" || card?.category === "SSS3" ? (
                <>
                  <button
                    className="border border-primary m-2 p-1 flex items-center justify-center rounded-sm"
                    onClick={() => handleMark?.("graduated", card?.studentId)}
                  >
                    <small className="text-primary text-xs">
                      Graduate Student
                    </small>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="border border-primary m-2 p-1 flex items-center justify-center rounded-sm"
                    onClick={() => handleMark?.("promoted", card?.studentId)}
                  >
                    <small className="text-primary text-xs">
                      Promote Student
                    </small>
                  </button>

                  <button
                    className="border border-saffron m-2 p-1 flex items-center justify-center rounded-sm"
                    onClick={() => handleMark?.("retained", card?.studentId)}
                  >
                    <small className="text-saffron text-xs">
                      Retain Student
                    </small>
                  </button>
                </>
              )}
              <button
                className="border border-coral_red m-2 p-1 flex items-center justify-center rounded-sm"
                onClick={() => handleMark?.("left", card?.studentId)}
              >
                <small className="text-coral_red text-xs">Mark as left</small>
              </button>
            </div>
          ) : null}
        </div>
      );
    }
  }

  return { getReportCardActions, showButton, index };
}
