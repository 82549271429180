import { useRef } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { BiArrowBack } from "react-icons/bi";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
  selectCalendar,
  selectSchoolDetails,
} from "../../../redux/slices/school/selectors";
import {
  getAverage,
  getGrade,
  getRemark,
  getStudentClassStatus,
  getSubjectFromcards,
} from "../../../shared/utils/functions";

import PageLoader from "../../../shared/components/PageLoader";
import useFetchStudentBasicInfo from "../../../shared/api/hooks/useFetchStudentBasicInfo";
import useFetchStudentReportCard from "../../../shared/api/cards/useFetchStudentReportCard";
import ReportCard from "../../../shared/components/classes/studentPreviewReportCard/ReportCard";
import ReportCard2 from "../../../shared/components/classes/studentPreviewReportCard/ReportCard2";
import ReportCard3 from "../../../shared/components/classes/studentPreviewReportCard/ReportCard3";

const StudentPreviewReportCard = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const session = query.get("session");
  const term = query.get("term");

  const reportCardRef = useRef();
  const { studentId } = useParams();
  const navigate = useNavigate();

  const calendar = useSelector(selectCalendar);
  const school = useSelector(selectSchoolDetails);

  const { loading: studentDetailsLoading, data: studentDetails } =
    useFetchStudentBasicInfo(studentId);

  const {
    reportCard,
    scorecards,
    firstTermScorecards,
    secondTermScorecards,
    loading: reportCardLoading,
  } = useFetchStudentReportCard(session, term);

  if (reportCardLoading || studentDetailsLoading) {
    return <PageLoader loading={reportCardLoading || studentDetailsLoading} />;
  }

  return (
    <div>
      <div className="page">
        <div className="mb-5 flex items-center justify-between">
          <div className="page_title_container">
            <h1 className="page_title">
              {`${studentDetails?.lastName || ""} ${
                studentDetails?.firstName || ""
              }'s`}{" "}
              Report Card
            </h1>
          </div>

          <ReactToPrint
            trigger={() => (
              <button
                className="flex items-center justify-center gap-2 bg-primary h-8 xxs:h-8 ssm:h-9 rounded-sm text-white px-3 min-w-[80px]"
                onClick={() => {}}
              >
                <IoCloudDownloadOutline className="text-sm" />
                <small>Download</small>
              </button>
            )}
            content={() => reportCardRef.current}
          />
        </div>

        <div className="bg-white p-3">
          {/* <NavLink
            to={`/${schoolId}/classes/${classId}/students/${studentId}/reportcard?session=${session}&term=${term}`}
          >
            <button className="px-3 py-2 flex items-center gap-2 text-yankee_blue hover:bg-primary_light_30 hover:text-primary">
              <BiArrowBack className="text-sm" />
              <small>Back to cards</small>
            </button>
          </NavLink> */}
          <button
            className="px-3 py-2 flex items-center gap-2 text-yankee_blue hover:bg-primary_light_30 hover:text-primary"
            onClick={() => navigate(-1)}
          >
            <BiArrowBack className="text-sm" />
            <small>Back to cards</small>
          </button>
        </div>

        {term === "first" ? (
          <div className="main_content">
            <ReportCard
              ref={reportCardRef}
              school={school}
              subjects={scorecards}
              reportCard={reportCard}
              getGrade={getGrade}
              getRemark={getRemark}
              getAverage={getAverage}
              vacationDate={new Date(
                calendar?.vacationStartDate
              )?.toDateString()}
              closingDate={new Date(calendar?.closingDate)?.toDateString()}
              nextResumptionDate={new Date(
                calendar?.nextResumptionDate
              )?.toDateString()}
              endSessionRemark={getStudentClassStatus(
                reportCard?.studentClassHistory,
                "end"
              )}
            />
          </div>
        ) : null}

        {term === "second" ? (
          <div className="main_content">
            <ReportCard2
              school={school}
              ref={reportCardRef}
              getGrade={getGrade}
              subjects={scorecards}
              getRemark={getRemark}
              reportCard={reportCard}
              getAverage={getAverage}
              getSubjectFromcards={getSubjectFromcards}
              vacationDate={new Date(
                calendar?.vacationStartDate
              )?.toDateString()}
              nextResumptionDate={new Date(
                calendar?.nextResumptionDate
              )?.toDateString()}
              endSessionRemark={getStudentClassStatus(
                reportCard?.studentClassHistory,
                "end"
              )}
              firstTermScorecards={firstTermScorecards}
              closingDate={new Date(calendar?.closingDate)?.toDateString()}
            />
          </div>
        ) : null}

        {term === "third" ? (
          <div className="main_content">
            <ReportCard3
              school={school}
              ref={reportCardRef}
              getGrade={getGrade}
              subjects={scorecards}
              getRemark={getRemark}
              reportCard={reportCard}
              getAverage={getAverage}
              getSubjectFromcards={getSubjectFromcards}
              vacationDate={new Date(
                calendar?.vacationStartDate
              )?.toDateString()}
              nextResumptionDate={new Date(
                calendar?.nextResumptionDate
              )?.toDateString()}
              endSessionRemark={getStudentClassStatus(
                reportCard?.studentClassHistory,
                "end"
              )}
              firstTermScorecards={firstTermScorecards}
              secondTermScorecards={secondTermScorecards}
              closingDate={new Date(calendar?.closingDate)?.toDateString()}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StudentPreviewReportCard;
