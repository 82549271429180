import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Subjects from "./Subjects";
import useFetchClassSubjects from "../../../../shared/api/classes/subjects/useFetchClassSubjects";
import Loader from "../../../../shared/components/Loader";
import FormButton from "../../../../shared/components/form/FormButton";
import useCreateScorecard from "../../../../shared/api/cards/useCreateScorecard";
import { handleToast } from "../../../utils/functions";
import { MdFilterList, MdFilterListOff } from "react-icons/md";

const NewScorecard = ({
  action,
  reportCard,
  fetchReportCard,
  handleCancelNewScorecard,
  studentDepartment,
  category,
}) => {
  const { classId, studentId } = useParams();
  const { loading: subjectLoading, data: subjects } =
    useFetchClassSubjects(classId);

  const { request: createScorecardRequest, loading: addingScorecard } =
    useCreateScorecard();
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const [filter, setFilter] = useState(studentDepartment || "all");
  const [filteredSubjects, setFilteredSubjects] = useState([]);

  async function createScorecard() {
    if (addingScorecard) return;
    if (!selectedSubject) return handleToast("No subject selected", "warning");
    if (!reportCard) return handleToast("No report card selected", "warning");

    const isSuccess = await createScorecardRequest({
      reportCardId: reportCard?.reportCardId,
      classSubjectId: selectedSubject?.classSubjectId,
      studentId,
    });

    if (isSuccess) {
      setSelectedSubject(null);
      fetchReportCard();
      handleCancelNewScorecard();
    }
  }

  function handleSelectSubject(params) {
    setSelectedSubject(params);
  }

  function handleFilterSubjects(params) {
    setFilter(params);
  }

  useEffect(() => {
    if (filter === "all") {
      return setFilteredSubjects(subjects);
    }

    const filtered = subjects?.filter(
      (el) =>
        el?.department?.toLowerCase() === filter ||
        el?.department?.toLowerCase() === "general"
    );

    setFilteredSubjects(filtered);
  }, [subjects, filter]);

  if (action !== "new-scorecard") return null;

  if (subjectLoading) return <Loader loading={subjectLoading} />;

  return (
    <div>
      <div className="mb-5">
        <div className="flex items-center justify-between gap-4 mb-2">
          <h2 className="side_page_title !mb-0 capitalize">New Scorecard</h2>

          {category?.startsWith("SSS") ? (
            <div
              className="pl-3 cursor-pointer"
              onClick={() => setShowFilter(!showFilter)}
            >
              {showFilter ? (
                <MdFilterListOff size={25} />
              ) : (
                <MdFilterList size={25} />
              )}
            </div>
          ) : null}
        </div>
        <p className="text-xs text-independence">
          Select from subjects below to create a new scorecard
        </p>
      </div>

      {showFilter ? (
        <div className="flex items-center justify-between gap-1 mb-5">
          {filters?.map((item, i) => (
            <button
              key={i}
              className={`flex-1 text-sm py-1 px-2 ${
                filter === item.value ? "border border-primary rounded-sm" : ""
              }`}
              onClick={() => handleFilterSubjects(item.value)}
            >
              {item.label}
            </button>
          ))}
        </div>
      ) : null}

      <Subjects
        subjects={filteredSubjects}
        selectedSubject={selectedSubject}
        handleSelectSubject={handleSelectSubject}
        addingScorecard={addingScorecard}
        createScorecard={createScorecard}
      />

      <FormButton
        title={"Cancel"}
        className={"!bg-transparent !text-coral_red !border-coral_red"}
        onClick={() => {
          setSelectedSubject(null);
          handleCancelNewScorecard();
        }}
      />
    </div>
  );
};

const filters = [
  { label: "All", value: "all" },
  { label: "Art", value: "art" },
  { label: "Science", value: "science" },
  { label: "Commercial", value: "commercial" },
];

export default NewScorecard;
