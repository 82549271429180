import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { selectCalendar } from "../../../../redux/slices/school/selectors";

import ChooseSubjects from "./ChooseSubjects";
import useCreateStudentReportCard from "../../../../shared/api/cards/useCreateStudentReportCard";
import useFetchClassSubjects from "../../../../shared/api/classes/subjects/useFetchClassSubjects";

const NewReportCard = ({
  student,
  fetchReportCard,
  handleCancelNewReportcard,
  action,
  category,
}) => {
  const { classId, studentId } = useParams();
  const calendar = useSelector(selectCalendar);

  const { loading: subjectLoading, data: subjects } =
    useFetchClassSubjects(classId);
  const { request: createReportCardRequest, loading } =
    useCreateStudentReportCard();

  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [filter, setFilter] = useState(student?.department || "all");

  useEffect(() => {
    if (filter === "all") {
      return setFilteredSubjects(subjects);
    }

    const filtered = subjects?.filter(
      (el) =>
        el?.department?.toLowerCase() === filter ||
        el?.department?.toLowerCase() === "general"
    );

    setFilteredSubjects(filtered);
    return setSelectedSubjects([]);
  }, [subjects, filter]);

  function handleSelect(params) {
    if (loading) return;

    const foundIndex = selectedSubjects.findIndex(
      (element) => element?.subjectId === params?.subjectId
    );

    if (foundIndex >= 0) {
      setSelectedSubjects(
        selectedSubjects.filter((el) => el?.subjectId !== params?.subjectId)
      );
    } else {
      setSelectedSubjects([
        ...selectedSubjects,
        {
          subjectId: params?.subjectId,
          schoolSubjectId: params?.schoolSubjectId,
          classSubjectId: params?.classSubjectId,
          name: params?.name,
        },
      ]);
    }
  }

  function handleFilterSubjects(params) {
    setFilter(params);
  }

  async function createReportCard() {
    if (!calendar) return;

    const isSuccess = await createReportCardRequest({
      studentId,
      subjects: selectedSubjects,
      session: calendar?.session,
      term: calendar?.term,
      classId,
      calendarId: calendar?.calendarId,
    });
    if (isSuccess) {
      setSelectedSubjects([]);
      await fetchReportCard();
      handleCancelNewReportcard();
    }
  }

  if (action !== "new-reportcard") return null;

  return (
    <div>
      <h2 className="side_page_title">Create Report Card</h2>

      <div className="mb-7">
        <p className="text-xs text-yankee_blue font-semibold mb-2">
          <span className="font-normal text-morning_blue">Session:</span>{" "}
          {calendar?.session}
        </p>

        <p className="text-xs text-yankee_blue font-semibold mb-2 capitalize">
          <span className="font-normal text-morning_blue ">Term:</span>{" "}
          {calendar?.term} term
        </p>

        {student?.department ? (
          <p className="text-xs text-yankee_blue font-semibold mb-2">
            <span className="font-normal text-morning_blue">Department:</span>{" "}
            {student?.department} department
          </p>
        ) : null}

        <p className="text-xs text-yankee_blue font-semibold mb-2">
          <span className="font-normal text-morning_blue">
            Selected Subjects:
          </span>{" "}
          {selectedSubjects?.length || 0}
        </p>
      </div>

      <ChooseSubjects
        subjects={filteredSubjects}
        handleSelectSubject={handleSelect}
        selectedSubjects={selectedSubjects}
        subjectLoading={subjectLoading}
        title={"Select subjects for student"}
        createReportCard={createReportCard}
        loading={loading}
        onFilter={handleFilterSubjects}
        filter={filter}
        category={category}
      />
    </div>
  );
};
export default NewReportCard;
