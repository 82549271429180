import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Navigate, Route, Routes } from "react-router-dom";

import Container from "./layout/Container";
import ClassContainer from "./layout/ClassContainer";

import Classes from "./pages/Classes";
import Login from "./pages/auth/Login";
import Teachers from "./pages/Teachers";
import Calendar from "./pages/Calendar";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import Billing from "./pages/Settings/Billings";
import NewEvent from "./pages/Calendar/NewEvent";
import Announcements from "./pages/Announcements";
import Notifications from "./pages/Notifications";
import EditEvent from "./pages/Calendar/EditEvent";
import VerifyEmail from "./pages/auth/VerifyEmail";
import Attendance from "./pages/Classes/Attendance";
import Scorecards from "./pages/Classes/Scorecards";
import NewStudent from "./pages/Classes/NewStudent";
import ReportCards from "./pages/Classes/ReportCards";
import ResetPassword from "./pages/auth/ResetPassword";
import ClassDetails from "./pages/Classes/ClassDetails";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetEmailSent from "./pages/auth/ResetEmailSent";
import Transactions from "./pages/Settings/Transactions";
import AdminDetails from "./pages/Settings/AdminDetails";
import ClassSubjects from "./pages/Classes/ClassSubjects";
import SchoolProfile from "./pages/Settings/SchoolProfile";
import StudentDetails from "./pages/Classes/StudentDetails";
import BillingInvoice from "./pages/Settings/BillingInvoice";
import PendingInvites from "./pages/Teachers/PendingInvites";
import Announcement from "./pages/Announcements/Announcement";
import Notification from "./pages/Notifications/Notification";
import ChoosePlan from "./pages/auth/Registration/ChoosePlan";
import BillingInvoices from "./pages/Settings/BillingInvoices";
import LeftStudents from "./pages/Archives/students/LeftStudents";
import OwnerDetails from "./pages/auth/Registration/OwnerDetails";
import StudentReportCard from "./pages/Classes/StudentReportCard";
import VerifyPasswordReset from "./pages/auth/VerifyPasswordReset";
import SchoolDetails from "./pages/auth/Registration/SchoolDetails";
import GraduatedStudents from "./pages/Classes/archives/GraduatedStudents";
import ArchivedReportcards from "./pages/Classes/archives/ArchivedReportcards";
import JuniorCurriculums from "./pages/Settings/curriculums/JuniorCurriculums";
import SeniorCurriculums from "./pages/Settings/curriculums/SeniorCurriculums";
import StudentPreviewReportCard from "./pages/Classes/StudentPreviewReportCard";
import ArchivedReportcardDetails from "./pages/Classes/archives/ArchivedReportcardDetails";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/login" />} />
        <Route path="login" element={<Login />} />

        <Route path="register/personal-details" element={<OwnerDetails />} />
        <Route
          path="register/:schoolOwnerId/school-details"
          element={<SchoolDetails />}
        />
        <Route path="register/:schoolId/plan" element={<ChoosePlan />} />

        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password/sent" element={<ResetEmailSent />} />
        <Route path="password/reset/:token" element={<VerifyPasswordReset />} />
        <Route path="password/change/:token" element={<ResetPassword />} />
        <Route path="verify/email/:userId/:token" element={<VerifyEmail />} />

        <Route element={<Container />}>
          <Route path=":schoolId/dashboard" element={<Dashboard />} />

          {/* Teachers */}
          <Route path=":schoolId/teachers" element={<Teachers />} />
          <Route
            path=":schoolId/teachers/invites"
            element={<PendingInvites />}
          />

          <Route path=":schoolId/school-classes" element={<Classes />} />

          {/* Archives */}
          <Route
            path=":schoolId/archives/left-students"
            element={<LeftStudents />}
          />

          {/* Calendar */}
          <Route
            path=":schoolId/calendar/event/new/:date"
            element={<NewEvent />}
          />
          <Route
            path=":schoolId/calendar/event/edit/:eventId"
            element={<EditEvent />}
          />
          <Route path=":schoolId/calendar" element={<Calendar />} />

          {/* Announcements */}
          <Route
            path=":schoolId/announcements/:announcementId"
            element={<Announcement />}
          />
          <Route path=":schoolId/announcements" element={<Announcements />} />

          {/* Notifications */}
          <Route
            path=":schoolId/notifications/:notificationId"
            element={<Notification />}
          />
          <Route path=":schoolId/notifications" element={<Notifications />} />

          {/* Settings */}
          <Route path=":schoolId/settings" element={<Settings />} />
          <Route path=":schoolId/settings/billing" element={<Billing />} />
          <Route
            path=":schoolId/settings/billing/invoices"
            element={<BillingInvoices />}
          />
          <Route
            path=":schoolId/settings/billing/invoices/:invoiceId"
            element={<BillingInvoice />}
          />
          <Route
            path=":schoolId/settings/billing/transactions"
            element={<Transactions />}
          />
          <Route path=":schoolId/settings/admin" element={<AdminDetails />} />
          <Route path=":schoolId/settings/school" element={<SchoolProfile />} />
          <Route
            path=":schoolId/settings/curriculum/junior"
            element={<JuniorCurriculums />}
          />
          <Route
            path=":schoolId/settings/curriculum/senior"
            element={<SeniorCurriculums />}
          />
        </Route>

        {/* Class */}
        <Route element={<ClassContainer />} path=":schoolId/classes">
          <Route path=":classId" element={<ClassDetails />} />

          <Route
            path=":classId/students/:studentId/reportcard"
            element={<StudentReportCard />}
          />
          <Route
            path=":classId/students/:studentId/reportcard/:reportcardId"
            element={<StudentPreviewReportCard />}
          />

          <Route path=":classId/attendance" element={<Attendance />} />

          <Route path=":classId/scorecards" element={<Scorecards />} />

          <Route path=":classId/subjects" element={<ClassSubjects />} />

          <Route path=":classId/add-student" element={<NewStudent />} />

          <Route path=":classId/reportcards" element={<ReportCards />} />

          <Route
            path=":classId/student/:studentId"
            element={<StudentDetails />}
          />

          <Route
            path=":classId/archives/reportcard"
            element={<ArchivedReportcards />}
          />
          <Route
            path=":classId/archives/reportcard/:reportCardId"
            element={<ArchivedReportcardDetails />}
          />
          <Route
            path=":classId/archives/reportcard/:reportCardId/student/:studentId"
            element={<StudentPreviewReportCard />}
          />

          <Route
            path=":classId/archives/graduated-students/:category"
            element={<GraduatedStudents />}
          />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default App;
